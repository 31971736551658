/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'slash-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M11.354 4.646a.5.5 0 00-.708 0l-6 6a.5.5 0 00.708.708l6-6a.5.5 0 000-.708"/>',
    },
});
